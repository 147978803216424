exports.components = {
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-barcelona-js": () => import("./../../../src/pages/barcelona.js" /* webpackChunkName: "component---src-pages-barcelona-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-madrid-js": () => import("./../../../src/pages/madrid.js" /* webpackChunkName: "component---src-pages-madrid-js" */),
  "component---src-pages-utopicus-js": () => import("./../../../src/pages/utopicus.js" /* webpackChunkName: "component---src-pages-utopicus-js" */)
}

